import React from 'react';
import Layout from '../../../layouts/index';

import Success from '../../../components/PaymentSuccess';

const PaymentSuccessContainer = () => (
  <Layout>
    <Success />
  </Layout>
);

export default PaymentSuccessContainer;
