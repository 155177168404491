import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { trackEvent } from '../services/analytics';

import MenuBar from '../components/Home/MenuBar';
import Footer from '../components/Home/Footer';
import MobileApps from '../components/Home/MobileApps';

import { colors, margins, mobileThresholdPixels, Content, Container, Subtitle2, OrderSummaryText }
  from './Home/v2/StyledComponents';

import backgroundImg from '../assets/order/orderSummaryHigh.jpg';

const Background = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  padding: ${margins.l};

  background-size: cover;
  background-color: ${colors.white};
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    width: unset;
    padding: ${margins.m};
  }
`;

const PaymentContainer = styled.div`
  width: calc(590px - 2 * ${margins.l});
  background-color: ${colors.white};
  padding: 0px ${margins.l} ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 4 * ${margins.m});
    padding: 0px ${margins.m} ${margins.m};
  }
`;

const StyledSubTitle2 = styled(Subtitle2)`
  text-align: left;
  margin-top: ${margins.l};
`;

const TextContainer = styled(OrderSummaryText)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const TextLink = styled(TextContainer)`
  text-decoration: underline;
  text-align: center;
  margin-top: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

function reinitOrder(trackingOriginElement, dispatch) {
  trackEvent('click', trackingOriginElement);
  dispatch({ type: 'ORDER.INIT' });
}

const Success = ({ dispatch }) => (
  <Container>
    <MenuBar />
    <Content>
      <Background>
        <PaymentContainer>
          <StyledSubTitle2>Votre moyen de paiment a bien été enregistré</StyledSubTitle2>
          <TextContainer>
            Pour suivre votre commande ou parrainer vos amis, vous pouvez vous connecter sur notre application :
          </TextContainer>

          <MobileApps smallTopMargin />

          <Link
            to={'/'}
            style={{ flex: 1, textAlign: 'center' }}
            onClick={() => reinitOrder('home_success', dispatch)}
          >
            <TextLink>retour à l‘accueil</TextLink>
          </Link>
        </PaymentContainer>
      </Background>
    </Content>
    <Footer />
  </Container>
);

Success.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Success);
